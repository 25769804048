<template>
  <el-form :model="form" ref="form" :rules="rules">
    <ul class="form_list clearfix verification-code-container">
      <li class="verification-code fl">
        <InputForm
          :label="$t('common.field.enterVerificationCode')"
          name="code"
          type="code"
          testId="code"
          v-model="form.code"
        ></InputForm>
      </li>
      <li class="fr">
        <el-button
          class="el-button purple_button"
          :disabled="isSendEmail"
          @click="sendEmail()"
          data-testid="code-button"
          >{{ isClickSendEmail ? $t('common.button.resendCode') : $t('common.button.sendCode') }}
          {{ displayTime > 0 ? `(${displayTime})` : '' }}</el-button
        >
      </li>
    </ul>
    <div class="err-code" v-if="errorCode !== 0">
      {{ $t(`responseMsg.${errorCode}`) }}
    </div>
    <div class="remind-email" v-if="isClickSendEmail">
      {{ $t('withdraw.otherMethod.verificationCode', { email: getEmail, supportEmail: getSupportEmail }) }}
    </div>
  </el-form>
</template>

<script>
import { apiSendEmailVerificationCode } from '@/resource';

export default {
  name: 'VerificationCode',
  props: {
    namespace: String,
    token: {
      type: String,
      default: null
    },
    maskedEmail: {
      type: String,
      default: null
    },
    callbackRegulator: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      form: {
        code: ''
      },
      rules: {
        code: [
          {
            required: true,
            message: this.$t('common.formValidation.verCodeReq'),
            trigger: 'blur'
          }
        ]
      },
      isSendEmail: false,
      isClickSendEmail: false,
      secondTime: 60,
      displayTime: 0,
      timeInterval: null,
      errorCode: 0,
      txId: ''
    };
  },
  computed: {
    getEmail() {
      return this.maskedEmail ? this.maskedEmail : this.$store.state.common.emailMasked;
    },
    getSupportEmail() {
      return this.callbackRegulator ? this.$config.info.getEmail(this.callbackRegulator) : this.GLOBAL_CONTACT_EMAIL;
    }
  },
  methods: {
    sendEmail() {
      apiSendEmailVerificationCode({ namespace: this.namespace, token: this.token }).then(resp => {
        this.isSendEmail = true;
        this.isClickSendEmail = true;
        this.startTimer();
        switch (resp.data.code) {
          case 0:
            this.txId = resp.data.data.txId;
            break;
          case 530:
            this.$router.push({ name: 'login' });
            break;
          default:
            this.errorCode = resp.data.code;
            break;
        }
      });
    },
    startTimer() {
      this.displayTime = this.secondTime;
      this.timeInterval = setInterval(this.countDown, 1000);
    },
    countDown() {
      this.displayTime--;
      if (this.displayTime === 0) this.stopTimer();
    },
    stopTimer() {
      clearInterval(this.timeInterval);
      this.isSendEmail = false;
    },
    getFormDataOfChild() {
      return {
        txId: this.txId,
        code: this.form.code
      };
    },
    submitForm() {
      return this.$refs['form'].validate();
    }
  }
};
</script>

<style lang="scss" scoped>
.verification-code-container {
  display: flex;
  .verification-code {
    @include rtl-sass-prop(margin-right, margin-left, 1rem);
  }
}
.err-code {
  color: #e91d2c;
}
.remind-email {
  color: #0051ff;
}
@media (max-width: 550px) {
  .verification-code-container {
    flex-direction: column;
    text-align: center;
  }
}
</style>
