<template>
  <div id="forceChangePassword">
    <div class="content_box">
      <div class="inner">
        <div class="content_title">
          <h2>{{ $t('changePw.header') }}</h2>
          <p></p>
        </div>
        <div class="forceChangePassword_box">
          <div class="reset_form">
            <el-form
              label-position="top"
              :model="ruleChangePasswordForm"
              :rules="rulesChangePassword"
              ref="ruleChangePasswordForm"
            >
              <InputForm
                v-model.trim="ruleChangePasswordForm.pass"
                name="pass"
                :label="$t('changePw.field.newPw')"
                type="password"
              >
              </InputForm>
              <InputForm
                v-model.trim="ruleChangePasswordForm.checkPass"
                name="checkPass"
                :label="$t('changePw.field.confirmPw')"
                type="password"
              >
              </InputForm>
              <el-form-item>
                <VerificationCode
                  ref="verificationCode"
                  namespace="CP_FORCE_UPDATE_PASSWORD"
                  :token="token"
                  :maskedEmail="maskedEmail"
                  :callbackRegulator="callbackRegulator"
                ></VerificationCode>
              </el-form-item>
              <el-form-item class="btn_item">
                <el-button class="purple_button" @click.native="submitForm()" data-testid="submit">
                  {{ $t('common.button.submit') }}
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { md5 } from '@/util/encrypt';
import VerificationCode from '@/components/withdraw/VerificationCode';
import { apiForceUpdatePassword } from '@/resource';

export default {
  name: 'forceChangePassword',
  components: { VerificationCode },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.newPwReq')));
      } else {
        const reg = /^(?=.*[A-Z])(?=.*[!@#$%^&*.()])(?=.*[0-9])(?=.*[a-z]).{8,16}$/;
        if (!reg.test(value)) {
          callback(new Error(this.$t('common.formValidation.newPwFormat')));
        } else {
          callback();
        }
      }
    };
    const validateCheckPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.newPwReq')));
      } else if (value !== this.ruleChangePasswordForm.pass) {
        callback(new Error(this.$t('common.formValidation.confirmNotMatch')));
      }
      callback();
    };
    return {
      ruleChangePasswordForm: {
        pass: '',
        checkPass: ''
      },
      rulesChangePassword: {
        pass: [{ validator: validatePass, trigger: ['blur', 'change'] }],
        checkPass: [{ validator: validateCheckPass, trigger: ['blur', 'change'] }]
      },
      token: null,
      callbackRegulator: null,
      maskedEmail: null
    };
  },
  mounted() {
    this.token = this.$route.params.token;
    this.callbackRegulator = this.$route.params.callbackRegulator;
    this.maskedEmail = this.$route.params.maskedEmail;
    if (!this.token) this.goToLogin();
  },
  methods: {
    submitForm() {
      const [getPasswordForm, getVerifyCodeComponent] = [
        this.$refs.ruleChangePasswordForm,
        this.$refs.verificationCode
      ];
      const [verifyPasswordMethod, verifyCodeMethod] = [
        getPasswordForm.validate(),
        getVerifyCodeComponent.submitForm()
      ];

      Promise.all([verifyPasswordMethod, verifyCodeMethod]).then(valid => {
        if (valid) {
          this.forceUpdatePassword();
        } else return false;
      });
    },
    forceUpdatePassword() {
      const getVerifyCodeComponent = this.$refs.verificationCode;

      apiForceUpdatePassword({
        pass: md5(this.ruleChangePasswordForm.pass),
        checkPass: md5(this.ruleChangePasswordForm.checkPass),
        token: this.token,
        ...getVerifyCodeComponent.getFormDataOfChild()
      }).then(resp => {
        if (resp.data.code === 0 || resp.data.code === 530) this.goToLogin();
      });
    },
    goToLogin() {
      return this.$router.push('/login');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/forceChangePassword.scss';
</style>
